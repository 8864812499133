<template>
  <div class="common-header">
    <img :src="$http + '/img/logoHeader.png'" alt="" srcset="">
    <div class="tabs-nav">
      <div class="tabs-name" v-for="(item, index) in tabsList" :key="index" @click="tabFunc(item, index)" @mouseover="moveGifFunc(index)" @mouseout="leaveGifFunc">
        <!-- <img v-if="index === tabIndex || showGifIndex===index" src="../../assets/img/selectTab.gif" alt="" srcset=""> -->
        <div class="mouse-gif" v-if="index === tabIndex || showGifIndex===index">
          <span class="mouse-gif-icon1" style="width:2px"></span>
          <span class="mouse-gif-icon2" style="width:2px"></span>
          <span class="mouse-gif-icon3" style="width:2px"></span>
        </div>
        <div class="mouse-gif" v-if="index !== tabIndex && showGifIndex!==index"></div>
        <span>{{item.name}}</span>
      </div>
    </div>
    <!-- <div class="language-style" @click="toZh" v-if="showLanguage">
      <img :src="$http + '/img/languageEn.png'" alt="">
    </div> -->
    <!-- <div class="options-box">
      <span @click="showTabFunc">页签</span>
      <span @click="setlanguage">{{language}}</span>
    </div> -->
    <!-- <transition name="fade">
      <div class="tab-list" v-if="showTab">
        <div v-for="(item, index) in tabsList" :key="item.id" @click="tabFunc(item, index)">
          <i class="el-icon-share" v-if="tabIndex == index"></i>
          <span :class="tabIndex == index ? 'span-have-icon' : 'span-no-icon'">{{item.name}}</span>
        </div>
      </div>
    </transition> -->
    <!-- <div class="mask" v-if="showTab" @click="showTab=!showTab"></div> -->
  </div>
</template>

<script>
export default {
  // 是否展示中英文切换功能， 默认展示，英文邮箱确认页不展示
  // props: {
  //   showLanguage: {
  //     type: Boolean,
  //     default: true
  //   }
  // },
  data(){
    return{
      language: 'zh',
      showTab: false, // 是否展示tab页签
      showGifIndex: null, // 悬浮展示gif
      tabIndex: 0,
      tabsList: [
        {
          id: 'home',
          name: 'Home'
        },
        {
          id: 'products',
          name: 'Products'
        },
        {
          id: 'about',
          name: 'About'
        },
        {
          id: 'download',
          name: 'Download'
        },
        {
          id: 'help',
          name: 'Help'
        }
      ]
    }
  },
  methods:{
    // 设置语言
    setlanguage(){
      sessionStorage.setItem('language', sessionStorage.getItem('language') === 'zh' ? 'en' : 'zh')
      this.language = sessionStorage.getItem('language')
      let routePath = this.$route.fullPath // 获取路由完整路径
      // 跳转中/英文页面
      this.$router.push({
        path: this.language == 'zh' ? routePath.replace('zh', 'en') : routePath.replace('en', 'zh')
      })
    },
    // toZh(){
    //   let path = this.$route.path.replace('en', 'zh')
    //   this.Move()
    //   this.$router.push({
    //     path
    //   })
    // },
    // 鼠标移入tab标签
    moveGifFunc(index){
      this.showGifIndex = index
    },
    // 鼠标移出tab标签
    leaveGifFunc(){
      this.showGifIndex = null
    },
    // 展示tab的页签
    showTabFunc(){
      this.showTab = !this.showTab
    },
    //开启页面滚动
    Move(){
      let m =function(e){e.preventDefault();};
      document.body.style.overflow='';//出现滚动条
      document.removeEventListener("touchmove",m,{ passive:true });
    },
    // tab的点击方法
    tabFunc(item, index){
      if(this.tabIndex != index){
        // this.Move()
        this.tabIndex = index
        if(index == 0){
          this.$router.push({
            path: '/qy/en/home'
          })
        }
        if(index == 1){
          this.$router.push({
            path: '/qy/en/detail'
          })
        }
        if(index == 2){
          this.$router.push({
            path: '/qy/en/about'
          })
        }
        if(index == 3){
          this.$router.push({
            path: '/qy/en/download'
          })
        }
        if(index == 4){
          this.$router.push({
            path: '/qy/en/help'
          })
        }
      }
    },
  },
  created(){
    this.tabIndex = this.tabsList.findIndex(e=>e.name == this.$route.meta.title)
    this.language = sessionStorage.getItem('language')
  }
}
</script>

<style scoped lang='scss'>
  .common-header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
    width: 100%;
    padding: 0 252px 0 244px;
    height: 90px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cda566; 
    background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); 
    backdrop-filter: blur(10px); /* This is the fallback for Safari that doesn't support backdrop-filter */
    img{
      width: 134px;
      height: 55px;
    }
    // 第一套方案导航
    .tabs-nav{
      color: #fff;
      display: flex;
      .tabs-name{
        cursor: pointer;
        display: flex;
        margin-right: 74px;
        font-size: 16px;
        span{
          padding-top: 4px;
        }
        &:nth-last-of-type(1){
          margin-right: 0;
        }
        .mouse-gif{
          margin-right: 8px;
          margin-top: 2px;
          width: 10px;
          height: 20px;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          span{
            width: 2px;
            height: 20px;
            background: #f7dea4;
            border-radius: 24px;
          }
          .mouse-gif-icon1{
            height: 60%;
            animation: slidein 1s infinite;
            -webkit-animation: slidein 1s infinite;
            animation-direction: alternate;
          }
          .mouse-gif-icon2{
            height: 40%;
            animation: slidein1 1s infinite;
            -webkit-animation: slidein1 1s infinite;
            animation-direction: alternate;
          }
          .mouse-gif-icon3{
            height: 20%;
            animation: slidein2 1s infinite;
            -webkit-animation: slidein2 1s infinite;
            animation-direction: alternate;
          }
        }
        .slidein {
          -moz-animation-duration: 1s;
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -moz-animation-name: slidein;
          -webkit-animation-name: slidein;
          animation-name: slidein;
          -moz-animation-iteration-count: 1;
          -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
          -moz-animation-direction: alternate;
          -webkit-animation-direction: alternate;
          animation-direction: alternate;
        }
        @-moz-keyframes slidein {
          0% {
            height: 20%;
          }
          50% {
            height: 60%;
          }
          100% {
            height: 20%
          }
        }
        @-webkit-keyframes slidein {
          0% {
            height: 20%;
          }
          50% {
            height: 60%;
          }
          100% {
            height: 20%
          }
        }
        @keyframes slidein {
          0% {
            height: 20%;
          }
          50% {
            height: 60%;
          }
          100% {
            height: 20%
          }
        }
        .slidein1 {
          -moz-animation-duration: 1s;
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -moz-animation-name: slidein1;
          -webkit-animation-name: slidein1;
          animation-name: slidein1;
          -moz-animation-iteration-count: 1;
          -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
          -moz-animation-direction: alternate;
          -webkit-animation-direction: alternate;
          animation-direction: alternate;
        }
        @-moz-keyframes slidein1 {
          0% {
            height: 20%;
          }
          100% {
            height: 40%;
          }
        }
        @-webkit-keyframes slidein1 {
          0% {
            height: 20%;
          }
          100% {
            height: 40%;
          }
        }
        @keyframes slidein1 {
          0% {
            height: 20%;
          }
          100% {
            height: 40%;
          }
        }
        .slidein2 {
          -moz-animation-duration: 1s;
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -moz-animation-name: slidein2;
          -webkit-animation-name: slidein2;
          animation-name: slidein2;
          -moz-animation-iteration-count: 1;
          -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
          -moz-animation-direction: alternate;
          -webkit-animation-direction: alternate;
          animation-direction: alternate;
        }
        @-moz-keyframes slidein2 {
          0% {
            height: 60%;
          }
          50% {
            height: 20%;
          }
          100% {
            height: 60%
          }
        }
        @-webkit-keyframes slidein2 {
          0% {
            height: 60%;
          }
          50% {
            height: 20%;
          }
          100% {
            height: 60%
          }
        }
        @keyframes slidein2 {
          0% {
            height: 60%;
          }
          50% {
            height: 20%;
          }
          100% {
            height: 60%
          }
        }
        img{
          margin-right: 8px;
          width: 10px;
          height: 16px;
        }
      }
    }

    .language-style{
      position: absolute;
      right: 69px;
      top: 50%;
      transform: translateY(-40%);
      cursor: pointer;
      img{
        width: 41px;
        height: 41px;
      }
    }

    // 第二套方案导航
    .options-box{
      color: #fff;
      span{
        padding-left: 20px;
        cursor: pointer;
      }
    }
    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
    .tab-list{
      position: absolute;
      z-index: 99;
      right: 100px;
      top: 40px;
      padding: 0px 40px 0 16px;
      width: 140px;
      height: 500px;
      border-radius: 6px;
      background: linear-gradient(#000, transparent);
      div{
        margin-top: 30px;
        color: #fff;
        cursor: pointer;
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        padding: 4px 0px 4px 12px;
        text-align: left;
        border-radius: 6px 0px 0px 6px;
        i{
          margin-right: 12px;
        }
        .span-have-icon{
          margin-left: 0px;
        }
        .span-no-icon{
          margin-left: 28px;
        }
      }
    }
    .mask{
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent;
    }
  }
</style>